import React from 'react';

type Props = {
  size: number;
  color: string;
};

export function LogoMark({ size, color }: Props) {
  return (
    <svg
      height={size}
      viewBox="0 0 11 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.18551 0.945312L0.103027 2.02927L2.80922 4.73609L0.103027 7.44138L1.18704 8.52534L3.8917 5.81928L4.97571 4.73533L3.89247 3.65367L1.18551 0.945312ZM5.46183 7.0326V8.56361H10.8206V7.0326H5.46183Z"
        fill={color}
      />
    </svg>
  );
}
