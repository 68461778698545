import { GrowthBookProvider } from '@growthbook/growthbook-react';
import React, { useEffect, useLayoutEffect } from 'react';
import { AuthProvider, CompanyProvider, ProfileProvider } from 'ui/contexts';
import { GlobalStyle } from 'ui/styles';
import { growthBook } from 'ui/utils/growthBook';

import { DashboardGlobalStyle } from './globalStyle';
import { Router } from './pages';

export function App() {
  useLayoutEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const shouldClose = urlParams.has('close');

    
    if (shouldClose) {
      window.close();
    }
  }, []);

  useEffect(() => {
    growthBook.loadFeatures({ autoRefresh: true });
  }, []);

  return (
    <GrowthBookProvider growthbook={growthBook}>
      <AuthProvider>
        <ProfileProvider>
          <CompanyProvider>
            <GlobalStyle />
            <DashboardGlobalStyle />
            <Router />
          </CompanyProvider>
        </ProfileProvider>
      </AuthProvider>
    </GrowthBookProvider>
  );
}

export default App;
