import React from 'react';

export function Chevron({
  direction = 'down',
  size = 9,
  color = 'currentColor',
}) {
  switch (direction) {
    case 'down':
      return (
        <svg height={size} viewBox="0 0 14 9" fill={color}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 2.23771L11.998 0.307129L6.99859 5.13358L2.00202 0.307129L3.60087e-10 2.24044L4.99798 7.06415L7 8.99746L8.99778 7.06552L14 2.23771Z"
          />
        </svg>
      );
    default: // up
      return (
        <svg height={size} viewBox="0 0 14 9" fill={color}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 7.06698L2.00202 8.99756L7.00141 4.17111L11.998 8.99756L14 7.06425L9.00202 2.24053L7 0.307225L5.00222 2.23917L0 7.06698Z"
          />
        </svg>
      );
  }
}
