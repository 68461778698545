import React, { HTMLProps } from 'react';
import styled from 'styled-components';

const StyledSwitch = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;

  &:checked + label {
    background: var(--color-primary);
  }

  &:checked + label:after {
    left: calc(100% - 2.5%);
    transform: translateX(-100%);
  }
`;

type LabelProps = {
  size: number;
} & Pick<HTMLProps<HTMLInputElement>, 'onClick'>;

const StyledLabel = styled.label<LabelProps>`
  cursor: pointer;

  width: ${({ size }) => size * 2}px;
  height: ${({ size }) => size}px;
  background: grey;
  display: block;
  border-radius: ${({ size }) => size / 2}px;
  position: relative;
  /* opacity: 0.9; */

  &:hover {
    /* opacity: 1; */
  }

  &:after {
    content: '';
    position: absolute;
    top: 2.5%;
    left: 2.5%;
    width: 45%;
    height: 90%;
    background: #fff;
    border-radius: ${({ size }) => 0.5 * size}px;
    transition: 0.3s;
  }

  &:active:after {
    width: 65%;
  }
`;

type SwitchProps = {
  checked: boolean;
  size: number;
} & Pick<HTMLProps<HTMLInputElement>, 'onClick'>;

export function Switch({ checked, size = 30, onClick }: SwitchProps) {
  return (
    <>
      <StyledSwitch checked={checked} type="checkbox" className="switch" />
      <StyledLabel size={size} onClick={onClick} />
    </>
  );
}
