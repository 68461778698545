import React from 'react';

type Props = {
  size?: number;
  color?: string;
  className?: string;
  outline?: boolean;
};

export function ProfileIcon({ size, color, className, outline }: Props) {
  return outline ? (
    <svg height={size} viewBox="0 0 18 20" fill="none" className={className}>
      <path
        d="M8.74981 11.4108C13.2371 11.4108 16.8669 14.9887 16.8994 19.3999H0.60022C0.632696 14.9888 4.26251 11.4108 8.74981 11.4108ZM8.74991 9.12993C6.35648 9.12993 4.42845 7.21386 4.42845 4.86528C4.42845 2.51628 6.35651 0.600244 8.74991 0.600244C11.1433 0.600244 13.0714 2.51628 13.0714 4.86528C13.0714 7.21386 11.1433 9.12993 8.74991 9.12993Z"
        stroke={color}
        strokeWidth="1.2"
      />
    </svg>
  ) : (
    <svg viewBox="129.92 36.4 440.16 487.2" height={size}>
      <g>
        <path d="m350 292.88c-71.121 0-128.24-57.121-128.24-128.24 0-71.121 57.117-128.24 128.24-128.24s128.24 57.121 128.24 128.24c0 71.125-57.117 128.24-128.24 128.24z" />
        <path d="m570.08 523.6h-440.16c0-120.96 99.121-220.08 220.08-220.08 120.96-0.003907 220.08 99.117 220.08 220.08z" />
      </g>
    </svg>
  );
}

ProfileIcon.defaultProps = {
  size: 64,
  color: 'black',
  className: '',
  outline: true,
};
