import { createGlobalStyle } from 'styled-components';

export const DashboardGlobalStyle = createGlobalStyle`
  body {
    background: var(--color-dashboard-background);
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  h1 {
    font-size: var(--font-size-heading-xl);
    line-height: var(--line-height-xl);
  }

  .sc-papXJ.sc-cCsOjp.iKTruL.lggCiT{
    max-width: initial;
  }

  .sc-papXJ.sc-cCsOjp.iKTruL.ccnnUf {
    max-width: initial;
  }
  
  .custom-steps{
    width: 650px;
    display: flex;  
    align-items: center; 
    
  }

  .custom-steps .ant-steps-item-icon:after{
    background-color: #531DAB ;
  } 
  
  .custom-steps .ant-steps-item-title:after {
    background-color: red ;
  }
  
`;
