/* eslint-disable jsx-a11y/alt-text */
import { Button } from 'antd';
import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup} from 'firebase/auth';
import React from 'react';

export function DashboardLoginButton( props: Readonly<{ signInProvider: 'microsoft' | 'google' }> ) {
  const { signInProvider } = props;
  const providerText = signInProvider === 'microsoft' ? 'Microsoft' : 'Google';
  const handleSignIn = async () => {
    const auth = getAuth();
    let provider = new GoogleAuthProvider();
    if (signInProvider === 'microsoft') {
      provider = new OAuthProvider('microsoft.com');
    }

    try {
      const res = await signInWithPopup(auth, provider);
      // Sign in extension
      if (chrome.runtime) {
        const editorExtensionId = 'lohojfppjeknalpoklojhfnndocgekbd';
        chrome.runtime.sendMessage(editorExtensionId, {
          type: 'signinWithToken',
          auth: res,
        });
      }
      return true;
    } catch (e) {
      return e;
    }
  };

  return (
    <Button style={{ display: 'flex', gap: 10, width: '300px', height: '40px' }} onClick={handleSignIn}>
      {signInProvider === 'microsoft' && <img src={require('../../assets/icons/microsoft_logo.png')} width={20} />}
      {signInProvider === 'google' && <img src={require('../../assets/icons/google_logo.png')} width={20} />}
      Continue with {providerText}
    </Button>
  );
}
