import { useMemo } from 'react';

import { Profile } from '../types';

export const usePaymentFailed = (profile: Profile) => {
  const currentDateTime = new Date();
  const currentDate = Math.round(currentDateTime.getTime() / 1000);

  const paymentFailed = useMemo(
    () =>
      !!(
        profile &&
        !profile.is_canceled &&
        profile.subscription_ends &&
        profile.subscription_ends.seconds &&
        currentDate > profile.subscription_ends.seconds
      ),
    [profile]
  );

  return paymentFailed;
};
