import React from 'react';

type Props = {
  size?: number;
  color?: string;
  outline?: boolean;
};

export function QuestionIcon({ size, color, outline }: Props) {
  return outline ? (
    <svg height={size} viewBox="0 0 40 40" fillRule="evenodd" fill={color}>
      <path d="M35.88,31.35a20,20,0,0,0,3.76-11.71A19.64,19.64,0,1,0,20,39.29a20.65,20.65,0,0,0,7.43-1.43L37.3,40l-1.42-8.65Zm-8.65,3.87-.29.11A17.47,17.47,0,0,1,20,36.74a17.1,17.1,0,0,1,0-34.2l1.2,0A17.11,17.11,0,0,1,33.39,30.3l-.26.34,1,6.1Z" />
      <path d="M20,9.36a6.18,6.18,0,0,0-6.11,6.32h2.55a3.57,3.57,0,1,1,7.12,0A3.68,3.68,0,0,1,21.43,19a4.94,4.94,0,0,0-2.75,4.38v.51h2.64v-.51a2.13,2.13,0,0,1,1.22-2,6.18,6.18,0,0,0,3.57-5.7A6.18,6.18,0,0,0,20,9.36Z" />
      <path d="M20,25.24A1.73,1.73,0,1,0,21.73,27,1.73,1.73,0,0,0,20,25.24Z" />
    </svg>
  ) : (
    <svg height={size} fill={color} viewBox="0 0 40 40">
      <path d="M35.88,31.35a20,20,0,0,0,3.76-11.71A19.64,19.64,0,1,0,20,39.29a20.65,20.65,0,0,0,7.43-1.43L37.3,40l-1.42-8.65ZM18.27,27A1.73,1.73,0,1,1,20,28.7,1.74,1.74,0,0,1,18.27,27Zm3.05-3.56v.51H18.68v-.51A4.93,4.93,0,0,1,21.42,19,3.74,3.74,0,0,0,20,11.91a3.65,3.65,0,0,0-3.56,3.77H13.89a6.11,6.11,0,1,1,12.22,0,6.18,6.18,0,0,1-3.57,5.7A2.13,2.13,0,0,0,21.32,23.41Z" />
    </svg>
  );
}

QuestionIcon.defaultProps = {
  size: 20,
  color: 'black',
  outline: true,
};
