import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { useEffect, useState } from 'react';
import { useDebounce } from '../../hooks';
import styled from 'styled-components';

interface props {
  onSearch: (key: string) => void;
  toggleSearchFilter?: () => void;
  isToggleSearchFilter?: boolean;
}

const Search = styled(Input)`
  padding: 15px;
  max-width: 800px;
  border-radius: 50px;
}`;

export function SearchBar({
  onSearch,
  toggleSearchFilter,
  isToggleSearchFilter,
}: props) {
  const [searchedText, setSearchedText] = useState<string>('');

  const query = useDebounce<string>(searchedText, 500);

  useEffect(() => {
    onSearch(query);
  }, [query]);

  return (
    <section style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
      <Search
        onFocus={() => setSearchedText('')}
        value={searchedText}
        onChange={(e) => setSearchedText(e.target.value)}
        prefix={<SearchOutlined style={{ marginRight: 10 }} />}
        allowClear
        placeholder="Search in Hyperbrain 🧠"
        onPressEnter={() => onSearch(searchedText)}
      />
    </section>
  );
}
