import styled from 'styled-components';

export const Button = styled.button`
  padding: var(--button-padding, var(--button-padding-xs));
  background: var(--button-background, var(--color-dark-100));
  color: var(--color-dark-100);
  border: var(--button-border, none);
  border-radius: var(--button-radius);
  cursor: pointer;
  font-size: var(--font-size-body-sm);
  line-height: var(--line-height-xxs);
  font-weight: var(--font-weight-700);

  &:disabled {
    background: #c4c4c4;
    border-color: #c4c4c4;
  }
`;

export const LightBtn = styled.button`
  /* margin-bottom: var(--spacing-md); */
  color: var(--color-dark-100);
  background-color: #fafafa;
  padding: var(--button-padding, var(--button-padding-xs));
  border: var(--button-border, none);
  border-radius: var(--button-radius);
  cursor: pointer;
  font-size: var(--font-size-body-sm);
  line-height: var(--line-height-xxs);
  font-weight: var(--font-weight-700);

  &:disabled {
    background: #c4c4c4;
    border-color: #c4c4c4;
  }
`;

export const TransparentButton = styled(Button)`
  --button-background: transparent;
  --button-color: var(--color-dark-100);
  --button-border: none;
  color: var(--color-light-100);
`;
export const SelectTopic = styled.select`
  cursor: auto;
  border-radius: 5px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  display: inline-block;
  box-sizing: border-box;
  padding: 0.5em 2em 0.5em 0.5em;
  border: 1px solid #000000;
  line-height: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-size: 15px;
  color: var(--color-light-100);

  background-repeat: no-repeat;
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
    linear-gradient(135deg, currentColor 50%, transparent 50%);
  background-position: right 15px top 1em, right 10px top 1em;
  background-size: 5px 5px, 5px 5px;
`;

export const PreviousPageBtn = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 4px;
  color: var(--color-light-100);
  gap: 6px;

  width: 24px;
  height: 24px;
  left: 308px;
  top: 106px;

  background: #d9d9d9;
  border-radius: 16px;
`;

export const BackButton = styled.button`
  display: flex;
  padding: 8px 8px 8px 5px;
  background: var(--color-light-100);
  border-radius: 16px;
  width: 80px;
  cursor: pointer;
  justify-content: space-around;
  gap: 10px;
`;

export const GradientBtn = styled.button`
  background: linear-gradient(90deg, #4776e6 0%, #8e54e9 100%);
  box-shadow: 0px 3.65258px 7.30516px #bdacfb,
    inset 0px -0.547887px 1.82629px rgba(0, 0, 0, 0.37),
    inset 0.547887px 0.182629px 2.73943px rgba(255, 255, 255, 0.5);
  padding: 3.1331px 6.2662px;

  color: var(--color-light-100);
  border: none;
  border-radius: var(--button-radius);
  cursor: pointer;
  font-size: var(--font-size-body-sm);
  line-height: var(--line-height-xxs);
  font-weight: var(--font-weight-700);
  padding: 12px 40px 12px 40px;
`;

export const SecondGradientBtn = styled.button`
  /* background: linear-gradient(90deg, #4776e6 0%, #8e54e9 100%); */
  background: linear-gradient(to right, #ffdbdb, #96a6fd);

  box-shadow: 0px 3.65258px 7.30516px #bdacfb,
    inset 0px -0.547887px 1.82629px rgba(0, 0, 0, 0.37),
    inset 0.547887px 0.182629px 2.73943px rgba(255, 255, 255, 0.5);
  padding: 3.1331px 6.2662px;

  color: #353535;
  border: none;
  border-radius: var(--button-radius);
  cursor: pointer;
  font-size: var(--font-size-body-sm);
  line-height: var(--line-height-xxs);
  font-weight: var(--font-weight-700);
  padding: 12px 40px 12px 40px;
  width: 25%;
`;

export const BackGradientBtn = styled.button`
  color: rgba(142, 84, 233, 1);
  padding: 3.1331px 6.2662px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: var(--font-size-body-sm);
  line-height: var(--line-height-xxs);
  font-weight: var(--font-weight-700);
  margin-top: 20px;
`;
