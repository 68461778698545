import { Popover, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

const DisabledWrapper = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;

  & > * {
    pointer-events: none;
    opacity: 0.5;
  }
`;

interface Props {
  children: React.ReactNode;
  showPopover: boolean;
}

export function NotSubscribedPopover({ children, showPopover }: Props) {
  if (!showPopover) {
    return children;
  }

  return (
    <Popover
      overlayStyle={{ width: 300 }}
      content={
        <div>
          <Typography.Text>
            This feature is not available in your current subscription plan.
          </Typography.Text>
          <br />
          <br />
          <Typography.Link
            target="_blank"
            href="https://hypertype.app/dashboard/settings/subscription?utm_source=extension"
          >
            Click here to upgrade
          </Typography.Link>
        </div>
      }
      trigger="hover"
    >
      <DisabledWrapper>{children}</DisabledWrapper>
    </Popover>
  );
}
