import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from 'firebase-utils';

export const useAppSumoUser = () => {
  const appSumoUser = async (email: string) => {
    const userRef = collection(firestore, 'appsumo_users');
    const q = query(userRef, where('activation_email', '==', email));
    const users = await getDocs(q);

    if (users.docs.length) {
      const data = users.docs.map((doc) => doc.data());
      if (data.length) return data[0];
      return null;
    }

    return null;
  };

  return { appSumoUser };
};
