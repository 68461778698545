import styled from 'styled-components';

export const Container = styled.div`
  padding: 55px 40px 40px 40px;
  gap: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ClusterSlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  max-height: 800px;
  overflow: auto;
  margin-top: 10px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.746);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const ListContainer = styled.ul`
  padding: 0;
  margin: 0 0 var(--spacing-md);
  list-style: none;
  flex: 1;
  max-height: 800px;
  overflow: auto;
  padding: 0px 10px 0px 10px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.746);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
