import React, { Suspense, useEffect } from 'react';
// import ReactGA from 'react-ga';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { LoadingSpinner } from 'ui/components';

const AuthRouter = React.lazy(() => import('./auth'));
const DashboardRouter = React.lazy(() => import('./dashboard'));

export function Router() {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <BrowserRouter>
        <Routes>
          <Route path="/auth/*" element={<AuthRouter />} />
          <Route path="/dashboard/*" element={<DashboardRouter />} />
          <Route path="*" element={<Navigate to="/dashboard/home" />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}
