const baseURL = process.env.API_URL;
const dashboardBaseURL = process.env.DASHBOARD_BASE_URL;

const apiConfig = {
  emails: {
    getSyncedEmails: `${baseURL}/getSyncedEmails`,
  },
  files: {
    getDocuments:  `${baseURL}/getFiles`,
    downloadDocument: `${baseURL}/downloadFile`,
    deleteDocument: `${baseURL}/deleteFile`,
    uploadDoc: `${process.env.UPLOAD_DOC_API_URL}`,
    uploadFaq: `${process.env.UPLOAD_FAQ_API_URL}`, // TODO: change this to the correct url
  },
  notion:{
    getNotionPages: `${baseURL}/getNotionPages`,
  },
  getIntercomHMAC: `${dashboardBaseURL}/api/v1/intercom/hmac`,
  getClusters: `${baseURL}/getClusters`,
  getClusterData: `${baseURL}/getClusterData`,
  editClusterName: `${baseURL}/editClusterName`,
  searchFAQ: `${baseURL}/searchFAQ`,
  addFaq: `${baseURL}/addFaq`,
  updateFaq: `${baseURL}/updateFaq`,
  deleteFaq: `${baseURL}/deleteFaq`,
  cancelSubscription: `${baseURL}/cancelSubscription`,
  upgradeSubscription: `${dashboardBaseURL}/api/v1/subscription/upgrade`,
  sendEmail: `${process.env.EMAIL_INVITE_URL}/sendEmail`,
  sendSlackNotification: `${dashboardBaseURL}/api/v1/slack/webhook-relay`,
};

export default apiConfig;
