import {
  addDoc,
  collection,
  documentId,
  DocumentReference,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { firestore } from 'firebase-utils';
import { useCallback, useMemo } from 'react';

import { Department } from '../types';

export const useDepartments = (companyRef?: DocumentReference | null) => {
  const departmentsRef = useMemo(() => {
    return collection(firestore, 'departments');
  }, [firestore]);

  const getDepartmentsReference = useCallback(
    async (ids: string[] = []) => {
      const departmentsQuery = query(
        departmentsRef,
        where(documentId(), 'in', ids)
      );
      const result = await getDocs(departmentsQuery);
      return result.docs?.map(
        (item) => item.ref
      ) as DocumentReference<Department>[];
    },
    [departmentsRef]
  );

  const queryDepartments = useCallback(
    async (company: DocumentReference) => {
      const departmentsQuery = query(
        departmentsRef,
        where('company', '==', company),
        where('isPrivate', '==', false)
      );
      const response = await getDocs(departmentsQuery);
      return response.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Department[];
    },
    [departmentsRef]
  );

  const getAllDepartments = useCallback(async () => {
    if (!companyRef || !departmentsRef) {
      return [];
    }
    return queryDepartments(companyRef);
  }, [companyRef, departmentsRef]);

  const createDepartment = useCallback(
    async (
      { name, isPrivate }: Partial<Department>,
      company?: DocumentReference | null
    ) => {
      if (!company || !departmentsRef) {
        return null;
      }

      return addDoc(departmentsRef, {
        company,
        name,
        isPrivate,
      }) as Promise<DocumentReference<Department>>;
    },
    [departmentsRef]
  );

  const createPrivateDepartment = (
    name: string,
    company?: DocumentReference | null
  ) => createDepartment({ name, isPrivate: true }, company ?? companyRef);

  const createPublicDepartment = (
    name: string,
    company?: DocumentReference | null
  ) => createDepartment({ name, isPrivate: false }, company ?? companyRef);

  return {
    queryDepartments,
    getAllDepartments,
    createPrivateDepartment,
    createPublicDepartment,
    getDepartmentsReference,
  };
};
