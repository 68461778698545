import React, { HTMLProps } from 'react';
import styled from 'styled-components';

const StyledSlider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  margin: 5px 0;
  background: #d3d3d3;
  outline: none;
  /* opacity: 0.7; */
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 5px;

  &:hover {
    /* opacity: 1; */
  }

  &::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--color-primary);
    cursor: pointer;
  }
`;

type SliderProps = {
  min?: number;
  max?: number;
  current?: number;
} & Pick<HTMLProps<HTMLInputElement>, 'onChange' | 'onInput'>;

export function Slider({
  min = 0,
  max = 100,
  current = 50,
  onChange = () => {},
  onInput = () => {},
}: SliderProps) {
  return (
    <StyledSlider
      onChange={(event) => onChange(event)}
      onInput={(event) => onInput(event)}
      type="range"
      min={min}
      max={max}
      value={current}
      className="slider"
    />
  );
}
