import { getAuth, User } from 'firebase/auth';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useRefreshGmailTabs } from '../hooks/useRefreshGmailTabs';
import { sendSlackNotification } from '../hooks/useSlack';

interface Props {
  children: React.ReactNode;
}

type AuthContextInterface = {
  user: User | null;
  isLoading: boolean;
  isSignedIn: boolean;
  logout: () => Promise<void>;
};

const initialState = {
  user: null,
  isLoading: true,
  isSignedIn: false,
  logout: async () => {},
};

const AuthContext = createContext<AuthContextInterface>(initialState);
const useAuthContext = () => useContext(AuthContext);

function AuthProvider({ children }: Props) {
  const [user, setUser] = useState<User | null>(initialState.user);
  const [isLoading, setLoading] = useState<boolean>(initialState.isLoading);
  const refreshGmailTabs = useRefreshGmailTabs();
  const auth = getAuth();

  useEffect(() => {
    return auth.onAuthStateChanged(async (authUser: User | null) => {
      setUser(authUser);
      setLoading(false);
    });
  }, [auth]);

  const logout = useCallback(async () => {
    setLoading(true);
    return auth
      .signOut()
      .then(() => {
        setUser(null);
        refreshGmailTabs();
      })
      .catch((error: String) => {
        console.error('AuthContext: Error signing out', error);
        sendSlackNotification(
          `AuthContext: Error signing out : ${JSON.stringify(error)} :x: `
        );
      });
  }, [auth]);

  const contextValue = useMemo(
    () => ({
      user,
      isLoading,
      logout,
      isSignedIn: !!user,
    }),
    [user, isLoading, logout]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider, useAuthContext };
