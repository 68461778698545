import { GrowthBook } from '@growthbook/growthbook-react';

import { isProduction } from './isProduction';

export const growthBook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: 'sdk-jQme4uPK2KiScd',
  enableDevMode: !isProduction(),
  subscribeToChanges: true,
});

growthBook.init();