import apiConfig  from '../../../apps/dashboard/src/services/config/apiConfig';

const slackWebhookUrl = apiConfig.sendSlackNotification;

export function sendSlackNotification(message: string) {
  const payload = {
    text: `appTempId : ${localStorage.getItem(
      'appTempId'
    )} | message : ${message}`,
  };

  fetch(
    slackWebhookUrl,
    {
      method: 'post',
      body: JSON.stringify(payload),
    }
  )
    .then((response) => response)
    .then((response) => response)
    .catch((error) => error);
}

export function sendSubscriptionNotification(message: string) {
  console.log(message);

  const payload = {
    text: `appTempId : ${localStorage.getItem(
      'appTempId'
    )} | message : ${message}`,
  };

  fetch(
    'https://hooks.slack.com/services/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',
    {
      method: 'post',
      body: JSON.stringify(payload),
    }
  )
    .then((response) => response)
    .then((response) => response)
    .catch((error) => error);
}

export function sendSlackInfityDemoRequest(message: string) {
  console.log(message);

  const payload = {
    text: `appTempId : ${localStorage.getItem(
      'appTempId'
    )} | message : ${message}`,
  };

  fetch(
    'https://hooks.slack.com/services/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',
    {
      method: 'post',
      body: JSON.stringify(payload),
    }
  )
    .then((response) => response)
    .then((response) => response)
    .catch((error) => error);
}
