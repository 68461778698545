import { useMemo } from 'react';

import { useAuth } from './useAuth';

export const useCheckGmailUser = () => {
  const { user } = useAuth();

  const isGmailUser = useMemo(() => {
    if (!user) return false;
    return (
      user?.email.includes('gmail.com') ||
      user?.email.includes('googlemail.com')
    );
  }, [user]);

  return { isGmailUser };
};
