import { getApp } from 'firebase/app';
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';

const app = getApp();
export const config = getRemoteConfig(app);

config.defaultConfig = {
  terms_and_conditions: 'T&Cs',
};

config.settings.minimumFetchIntervalMillis = 1000;

fetchAndActivate(config);
