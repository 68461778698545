import { Row } from 'antd';
import styled from 'styled-components';

export const Heading = styled.div`
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-heading-xl);
  color: var(--color-dark-100);
  text-transform: capitalize;
`;

export const SubHeading = styled.div`
  font-weight: var(--font-weight-800);
  font-size: var(--font-size-body-md);
  color: var(--color-dark-100);
`;

export const TextHeading = styled.div`
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-body-md);
  color: var(--color-dark-100);
  text-transform: capitalize;
`;

export const GradientText = styled.div`
  background: linear-gradient(90deg, #4776e6 0%, #8e54e9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const HeaderRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: var(--container-max-width);
  gap: 20px;
  display: flex;
`;
