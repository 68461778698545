import React from 'react';
import styled from 'styled-components';

import { useAuth } from '../../hooks/useAuth';

type ButtonProps = {
  imageUrl: string;
  size: number;
};

const StyledButtonRound = styled.button<ButtonProps>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  border: none;
  border-radius: 50%;
  background-color: #c4c4c4;
  background-image: url(${(props) => props.imageUrl});
  background-repeat: no-repeat;
  background-size: 100%;
  /* cursor: pointer; */
  overflow: hidden;
`;

type Props = {
  size?: number;
};

export function UserAvatar({ size }: Props) {
  const { user } = useAuth();

  return (
    <StyledButtonRound size={size || 64} imageUrl={user?.photoURL || ''} />
  );
}
