import { useEffect, useState } from 'react';

import { Confidence } from '../types/Options';

const initialValue = {
  autoQuestion: 75,
  autoAnswer: 3,
  manualQuestion: 70,
  copycat: 85,
};

export function useConfidence() {
  const [confidence, setConfidence] = useState<Confidence>(initialValue);
  const [isLoading, setIsLoading] = useState(true);

  const setLocalStorage = (newConfidence: Partial<Confidence>) => {
    chrome.storage.sync.set(newConfidence);
    setConfidence((oldConfidence) => {
      return { ...oldConfidence, ...newConfidence };
    });
  };

  useEffect(() => {
    chrome.storage.sync.get(initialValue, (response) => {
      setLocalStorage(response as Confidence);
      setIsLoading(false);
    });
  }, []);

  return { isLoading, confidence, setConfidence: setLocalStorage };
}
