import { getAuth } from 'firebase/auth';
import {
  collection,
  doc,
  documentId,
  DocumentReference,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFirestore } from 'ui/hooks';
import { Profile } from 'ui/types';

import { Department } from '../types/Department';

interface Props {
  children: React.ReactNode;
}

type ProfileContextInterface = {
  profile: Profile | null;
  updateProfile: (newData: Partial<Profile>) => Promise<void>;
  isLoading: boolean;
  hasProfile: boolean;
  updateMemberProfile: (
    id: string,
    newData: Partial<Profile>
  ) => Promise<DocumentReference<Profile>> | null;
};

const initialState = {
  profile: null,
  updateProfile: async () => undefined,
  isLoading: true,
  hasProfile: false,
  updateMemberProfile: () => null,
};

const ProfileContext = createContext<ProfileContextInterface>(initialState);
const useProfileContext = () => useContext(ProfileContext);

function ProfileProvider({ children }: Props) {
  const firestore = useFirestore();
  const [profile, setProfile] = useState<Profile | null>(initialState.profile);
  const [isLoading, setLoading] = useState<boolean>(initialState.isLoading);
  const [profileRef, setProfileRef] = useState<DocumentReference | null>(null);

  const auth = getAuth();
  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      if (!user) {
        setLoading(false);
        setProfile(null);
        setProfileRef(null);
        return;
      }

      setLoading(true);
      setProfileRef(doc(firestore, 'users', user.uid));
    });
  }, [auth]);

  const dereferenceDepartments = async (
    departmentsList: DocumentReference<Department>[] = []
  ) => {
    if (!departmentsList || departmentsList?.length === 0) {
      return [];
    }
    const departmentsRef = collection(firestore, 'departments');
    const departmentsQ = query(
      departmentsRef,
      where(documentId(), 'in', departmentsList),
      where('isPrivate', '==', false)
    );
    const list = await getDocs(departmentsQ);
    return list.docs.map((document) => ({
      ...document.data(),
      id: document.id,
    })) as Department[];
  };

  useEffect(() => {
    if (!profileRef) {
      return undefined;
    }

    return onSnapshot(
      profileRef,
      (profileDoc: { data: () => Profile }) => {
        const profileData = profileDoc.data();

        // Ignore profile if force signup flag is set
        if (profileData?.forceSignup === true) {
          setProfile(null);
          setLoading(false);
          console.warn('ProfileContext forced signup');
          return;
        }
        if (!profileData) {
          setProfile(profileData);
          setLoading(false);
          return;
        }
        profileData.id = profileDoc.id;

        dereferenceDepartments(profileData?.departments).then(
          (dereferencedDepartments) => {
            setProfile({
              ...profileData,
              dereferencedDepartments,
            });
            setLoading(false);
          }
        );
      },
      (error: string) => console.error(error)
    );
  }, [profileRef, setProfile]);

  const updateProfile = useCallback(
    async (updateData: Partial<Profile>) => {
      if (!profileRef) {
        return;
      }

      if (profile) {
        await updateDoc(profileRef, updateData);
        return;
      }

      setDoc(profileRef, updateData, { merge: true });
    },
    [profileRef]
  );

  const updateMemberProfile = useCallback(
    async (email: string, newData: Profile) => {
      const userRef = collection(firestore, 'users');
      const q = query(userRef, where('email', '==', email));
      const users = await getDocs(q);

      if (users.docs.length) {
        const data = await updateDoc(users.docs[0].ref, newData);
        return data;
      }

      return null;
    },
    []
  );

  const contextValue = useMemo(
    () => ({
      profile,
      isLoading,
      hasProfile: !!profile,
      updateProfile,
      updateMemberProfile,
    }),
    [profile, isLoading, profile, updateProfile]
  );

  return (
    <ProfileContext.Provider value={contextValue}>
      {children}
    </ProfileContext.Provider>
  );
}

export { ProfileProvider, useProfileContext };
export default ProfileContext;
