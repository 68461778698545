import { useCompanyContext } from '../contexts';

export const useCompany = () => {
  const {
    company,
    isLoading,
    getCompanyRefFromDomain,
    updateCompany,
    addCompany,
    isExistingCompany,
  } = useCompanyContext();
  return {
    company,
    isLoading,
    getCompanyRefFromDomain,
    isExistingCompany,
    updateCompany,
    addCompany,
  };
};
