const GMAIL_DOMAINS = ['gmail.com', 'googlemail.com'];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const extractDomainName = (email: string, id: string) => {
  if (!email) {
    return null;
  }

  const domain = email?.split('@').pop();
  if (!domain) {
    return null;
  }

  const cleanedEmail = email.replace(/[.@]/g, '');

  if (GMAIL_DOMAINS.includes(domain)) {
    return `${cleanedEmail}`;
  }

  return domain;
};
