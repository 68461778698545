import React from 'react';

type Props = {
  size?: number;
  outline?: boolean;
};

export function HomeIcon({ size, outline }: Props) {
  return outline ? (
    <svg viewBox="165.2 84 369.6 392" height={size}>
      <g>
        <path d="m343.18 86.285-173.6 134.39c-2.7227 2.0938-4.3867 5.4922-4.375 8.9258v235.2c0 5.8633 5.3359 11.199 11.199 11.199h117.6c5.8633 0 11.199-5.3359 11.199-11.199v-89.598c0-25.012 19.785-44.801 44.801-44.801 25.012 0 44.801 19.785 44.801 44.801v89.598c0 5.8633 5.3359 11.199 11.199 11.199h117.6c5.8633 0 11.199-5.3359 11.199-11.199v-235.2c0-3.4336-1.6523-6.832-4.375-8.9258l-173.6-134.39c-4.1836-3.2773-10.023-2.8086-13.652 0zm6.8242 22.926 162.4 125.82v218.57h-95.199v-78.398c0-37.035-30.164-67.199-67.199-67.199s-67.199 30.164-67.199 67.199v78.398h-95.199v-218.57z" />{' '}
      </g>
    </svg>
  ) : (
    <svg viewBox="176.4 95.2 347.2 369.6" height={size}>
      <g>
        <path d="m350 95.203-173.6 134.4v235.2h117.6v-89.602c0-31.023 24.977-56 56-56s56 24.977 56 56v89.602h117.6v-235.2z" />
      </g>
    </svg>
  );
}

HomeIcon.defaultProps = {
  size: 64,
  outline: true,
};
