export const useRefreshGmailTabs = () => {
  return () => {
    chrome?.tabs
      ?.query({
        currentWindow: true,
      })
      .then((tabs) => {
        tabs
          .map((tab) => {
            return tab;
          })
          .filter((tab) => tab?.url?.includes('mail.google.com'));
      });
  };
};
