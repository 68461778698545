import styled from 'styled-components';

export const ButtonPrimary = styled.button`
  background-color: var(--color-button-primary);
  border-radius: 10px;
  color: var(--color-button-text-primary);
  padding: var(--button-padding-default);
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;

  
`;
