let trackingSession: string | null = null;

export const useTrackingSession = () => {
  if (!trackingSession) {
    trackingSession = Date.now().toString();
  }

  return trackingSession;
};

export const clearTrackingSession = () => {
  trackingSession = null;
};
