import styled from 'styled-components';

export const Input = styled.input`
  padding: var(--input-padding, var(--input-default-padding));
  border-radius: var(--input-radius, var(--input-default-border-radius));
  border: 1px solid #949494;
  font-size: var(--font-size-body-md);
  line-height: var(--line-height-xs);
  width: 100%;
  color: var(--color-dark-200);
`;
