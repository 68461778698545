import { createGlobalStyle } from 'styled-components';

import theme from './theme';

const GlobalStyle = createGlobalStyle`
  :root {
    ${theme};
  }
  * {
    box-sizing: border-box;
  }
  
  body {
    width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    a {
      cursor: pointer;
    }
    svg {
      pointer-events: none;
    }
    font-family: var(--font-family-body), sans-serif;
    button, input, textarea {
      font-family: var(--font-family-body), sans-serif;
    }
    background-color: var(--color-background-main);
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  #app {
    /* height: 100%; */
  }
  
  p, h6 {
      margin: 0;
      padding: 0;
  }

  button {
    user-select: none;
  }
`;

export default GlobalStyle;
