import { Row } from 'antd';
import styled from 'styled-components';

export const Box = styled(Row)`
  background: #ffffff;
  box-shadow: var(--box-box-shadow);
  border-radius: var(--box-border-radius);
  padding: var(--box-padding);
  color: var(--color-dark-100);
  max-width: var(--container-max-width);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ClusterBox = styled(Row)`
  padding: var(--box-top-bottom-padding);
`;
