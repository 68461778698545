import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Faq, FAQGroup } from '../types';
import { useProfile } from './useProfile';

const PAGE_SIZE = 10;

export const useFaq = (type: FAQGroup) => {
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [faqList, setFaqList] = useState<Faq[]>([]);
  const { profile } = useProfile();

  useEffect(() => {
    setFaqList([]);
  }, [type]);

  const faqRef = useMemo(() => {
    if (!profile) {
      return null;
    }

    return collection(profile.company, 'faq') as CollectionReference<Faq>;
  }, [profile]);

  const fetchById = async (id: string) => {
    if (!profile || !profile.company) {
      return null;
    }
    const itemRef = doc(profile.company, 'faq', id) as DocumentReference<Faq>;
    return getDoc(itemRef);
  };

  const addItem = async (faq: Faq) => {
    if (!faqRef) {
      return;
    }
    setFaqList((oldFaqList) => [faq, ...oldFaqList]);
  };

  const deleteItem = async (id: string) => {
    if (!faqRef) {
      return;
    }

    const newFaqList = faqList.filter((faq) => faq.id !== id);
    setFaqList(newFaqList);
  };

  const loadMore = async (reload = false) => {
    if (!faqRef || (isLoading && faqList.length)) {
      return null;
    }
    setIsLoading(true);

    let fetchQuery;

    fetchQuery = query(
      faqRef,
      where('kind', '==', type),
      orderBy('answer_confidence', 'desc'),
      limit(PAGE_SIZE)
    );

    // Start from last question if any
    if (!reload) {
      const lastQuestion = faqList[faqList.length - 1];
      const lastSnap = await getDoc(doc(faqRef, lastQuestion.id));
      fetchQuery = query(fetchQuery, startAfter(lastSnap));
    }

    return getDocs<Faq>(fetchQuery)
      .then((res) => {
        if (res.size < PAGE_SIZE) {
          setHasMore(false);
        }
        const resultsParsed = res.docs.map((item) => ({
          ...item.data(),
          id: item.id,
        }));
        setFaqList((currentList) => [...currentList, ...resultsParsed]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reloadList = useCallback(async () => {
    if (!faqRef) {
      return;
    }
    setFaqList((oldFaqList) => [...oldFaqList]);
  }, [faqRef]);

  const load = async () => {
    setHasMore(true);
    return loadMore(true);
  };

  useEffect(() => {
    if (!faqRef) {
      return;
    }

    load();
  }, [type, faqRef]);

  return {
    faqList,
    hasMore,
    isLoading,
    loadMore,
    fetchById,
    deleteItem,
    addItem,
    reloadList,
  };
};
