import {
  addDoc,
  collection,
  DocumentReference,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFirestore, useProfile } from 'ui/hooks';

import { Company } from '../types';
import { extractDomainName } from '../utils/extractDomainName';

interface Props {
  children: React.ReactNode;
}

type CompanyContextInterface = {
  company: Company | null;
  isLoading: boolean;
  hasCompany: boolean;
  updateCompany: (newData: Partial<Company>) => Promise<void>;
  addCompany: (newData: Partial<Company>) => Promise<void>;
  getCompanyRefFromDomain: (
    domain: string
  ) => Promise<DocumentReference<Company>> | null;
  isExistingCompany: (email: string, userId: string) => Promise<boolean>;
};

const initialState = {
  company: null,
  isLoading: true,
  hasCompany: false,
  updateCompany: async () => {},
  addCompany: async () => {},
  getCompanyRefFromDomain: () => null,
  isExistingCompany: async () => false,
};

const CompanyContext = createContext<CompanyContextInterface>(initialState);
const useCompanyContext = () => useContext(CompanyContext);

function CompanyProvider({ children }: Props) {
  const { profile, hasProfile, isLoading: isProfileLoading } = useProfile();
  const [company, setCompany] = useState<Company | null>(null);
  const [isLoading, setLoading] = useState<boolean>(initialState.isLoading);

  const companyRef = useMemo(() => {
    if (!profile) {
      return null;
    }
    return profile.company;
  }, [profile]);

  useEffect(() => {
    if (isProfileLoading) {
      return undefined;
    }

    if (!hasProfile) {
      setLoading(false);
      setCompany(null);
      return undefined;
    }

    if (!companyRef) {
      return undefined;
    }

    const unsubscribe = onSnapshot(
      companyRef,
      (companyDoc) => {
        setCompany(companyDoc.data() as Company);
        setLoading(false);
      },
      (error) => console.error(error)
    );

    return unsubscribe;
  }, [hasProfile, isProfileLoading, companyRef]);

  const updateCompany = useCallback(
    async (updateData: Partial<Company>) => {
      if (!companyRef) {
        return;
      }

      if (company) {
        await updateDoc(companyRef, updateData);
        return;
      }

      setDoc(companyRef, updateData, { merge: true });
    },
    [companyRef]
  );

  const getCompanyRefFromDomain = useCallback(async (domain: string) => {
    const firestore = useFirestore();
    const companiesRef = collection(firestore, 'companies');
    const q = query(companiesRef, where('domains', 'array-contains', domain));
    const companies = await getDocs(q);

    if (companies.docs.length) {
      return companies.docs[0].ref;
    }

    return null;
  }, []);

  const addCompany = useCallback(
    async (newData: Partial<Company>) => {
      const firestore = useFirestore();
      const companiesRef = collection(firestore, 'companies');

      if (!companiesRef) return null;

      const companies = await addDoc(companiesRef, newData);

      if (companies) {
        return companies;
      }

      return null;
    },
    [useFirestore]
  );

  const isExistingCompany = async (email: string) => {
    const domain = extractDomainName(email);
    if (!domain) {
      return null;
    }
    const reference = await getCompanyRefFromDomain(domain);
    return reference?.id;
  };

  const contextValue = useMemo(
    () => ({
      company,
      isLoading,
      hasCompany: !isLoading && !!company,
      updateCompany,
      getCompanyRefFromDomain,
      addCompany,
      isExistingCompany,
    }),
    [company, isLoading, company, isExistingCompany]
  );

  return (
    <CompanyContext.Provider value={contextValue}>
      {children}
    </CompanyContext.Provider>
  );
}

export { CompanyProvider, useCompanyContext };
