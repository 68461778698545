import { useEffect, useState } from 'react';

const KEY = 'userData';
const AREA_NAME = 'local';
export const useListenToAuthChange = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState<null | object>(null);

  useEffect(() => {
    setIsLoading(true);
    chrome?.storage?.local
      ?.get([KEY])
      .then((res) => {
        setUserData(res[KEY]);
      })
      .finally(() => setIsLoading(false));
  }, [setUserData, chrome?.storage]);

  useEffect(() => {
    const onChange = (changes: any, areaName: string) => {
      if (areaName === AREA_NAME && KEY in changes) {
        setUserData(changes[KEY].newValue);
      }
    };
    chrome?.storage?.onChanged?.addListener?.(onChange);
    return () => {
      chrome?.storage?.onChanged?.removeListener?.(onChange);
    };
  }, [chrome.storage, setUserData]);
  return {
    userData,
    isAuth: !!userData,
    isLoading,
  };
};
